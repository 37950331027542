<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Invoice : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- invoice name -->
          <b-form-group label="Invoice Name" label-for="invoice_name">
            <validation-provider #default="{ errors }" name="invoice_name">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Search name invoice"
                  v-model="filter.invoice_name"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- invoice jumlah -->
          <b-form-group label="Invoice Jumlah" label-for="invoice_jumlah">
            <validation-provider #default="{ errors }" name="invoice_jumlah">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Search jumlah invoice"
                  v-model="filter.invoice_jumlah"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- status invoice -->
          <b-form-group label="Invoice Status" label-for="invoice_status">
            <validation-provider #default="{ errors }" name="invoice_status">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <v-select
                  v-model="filter.invoice_status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusInvoice"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="3" class="mb-25">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="reloadPage"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="verifikasi(data.item)">
            <feather-icon icon="CheckCircleIcon" size="16" />
            <span class="align-middle ml-50">Verifikasi</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteInvoice(data.item)">
            <feather-icon icon="DeleteIcon" size="16" />
            <span class="align-middle ml-50">Delete Invoice</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input
          id="email"
          type="email"
          placeholder="Email Address"
          v-model="email"
        />
      </b-form-group>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="exportData()"
      >
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>

    <!-- modal approve-->
    <b-modal
      id="modal-verifikasi-invoice"
      title="Approve Mutasi Masuk"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <b-form @submit.prevent>
        <br />
        <b-form-input
          id="Id"
          type="Id"
          placeholder="Id Invoice"
          v-model="approveInvoice.id"
          hidden
        />
        <table style="width: 100%">
          <tr>
            <td>File</td>
            <td>:</td>
            <td v-if="approveInvoice.invoice_file != null">
              <a
                v-if="!approveInvoice.invoice_file.includes('https')"
                :href="
                  'https://www.idijakpus.or.id/uploads/invoice/invoice_file/' +
                    approveInvoice.id +
                    '/' +
                    approveInvoice.invoice_file
                "
                target="_blank"
              >
                {{ "lihat invoice" }}</a
              >
              <a v-else :href="approveInvoice.invoice_file" target="_blank">
                {{ "lihat invoice" }}</a
              >
            </td>
            <td v-else>{{ "file tidak di temukan" }}</td>
          </tr>
          <tr>
            <td>Invoice Id</td>
            <td>:</td>
            <td>{{ approveInvoice.id }}</td>
          </tr>
          <tr>
            <td>Nama Anggota</td>
            <td>:</td>
            <td>{{ approveInvoice.orang_id.orang_nama_lengkap }}</td>
          </tr>
          <tr>
            <td>Keterangan</td>
            <td>:</td>
            <td>{{ approveInvoice.invoice_name }}</td>
          </tr>
          <tr>
            <td>Jumlah</td>
            <td>:</td>
            <td>{{ approveInvoice.invoice_jumlah }}</td>
          </tr>
        </table>

        <br />
        <b-form-group label="Status" label-for="vue-select">
          <v-select
            id="status_tipe"
            v-model="approveInvoice.invoice_status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionInvoice"
            placeholder="Invoice Status.."
          />
        </b-form-group>
        <br />
        <b-button
          variant="primary"
          type="submit"
          block
          :disabled="invalid"
          @click="verifikasiInvoice"
        >
          Approve
        </b-button>
      </b-form>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BTable,
  BButton,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BFormFile,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCol,
  BModal,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ["multi", "single", "range"],
      fields: [
        { key: "id", label: "Id" },
        [{ key: "orang_id.orang_nama_lengkap", label: "anggota" }],
        [{ key: "invoice_name", label: "name" }],
        [{ key: "invoice_jumlah", label: "jumlah" }],
        [{ key: "created_at", label: "dibuat" }],
        [{ key: "invoice_status", label: "status" }],
        "action",
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],

      search: null,
      optionInvoice: ["belum-dibayar", "sudah-dibayar"],
      approveInvoice: {
        orang_id: {
          orang_nama_lengkap: "",
        },
      },
      filter: {
        invoice_name: null,
        invoice_jumlah: null,
        invoice_status: null,
      },
      statusInvoice: ["sudah-dibayar", "belum-dibayar"],
    };
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getInvoice();
      } else {
        this.currentPage = 1;
      }
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      }
      return `${files.length} files selected`;
    },

    verifikasi(item) {
      this.approveInvoice = item;
      this.$bvModal.show("modal-verifikasi-invoice");
    },
    reloadPage() {
      location.reload();
    },

    async getInvoice() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        // query data list invoice
        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          invoice_name: this.filter.invoice_name,
          invoice_jumlah: this.filter.invoice_jumlah,
          invoice_status: this.filter.invoice_status,
        };
        const { data } = await API.invoice.list(form);
        this.data_table = data;
        this.items = data.data;
        this.rows = data.meta.total;

        this.handleMsgSuccess("Berhasil tampilkan data invoice");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async verifikasiInvoice() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var id = this.approveInvoice.id;

        let dataInvoice = {
          invoice_status: this.approveInvoice.invoice_status,
        };
        const { data } = await API.invoice.approveInvoice(id, dataInvoice);
        this.handleMsgSuccess("Berhasil verifikasi invoice");
        this.refreshPage();
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "wrong sistem");
      } finally {
        this.$bvModal.hide("modal-verifikasi-invoice");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async deleteInvoice(ids) {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var id = ids.id;

        const { data } = await API.invoice.deleteData(id);
        this.handleMsgSuccess("Berhasil delete invoice");
        this.refreshPage();
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "wrong sistem");
      } finally {
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  mounted() {
    this.getInvoice();
  },
  watch: {
    currentPage() {
      this.getInvoice();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
